@font-face {
  font-family: "DINAlternate";
  src: url("./styles/DIN Alternate Regular.woff2") format("woff2");
  font-weight: 100;
}

@font-face {
  font-family: "DINAlternate";
  src: url("./styles/DIN Alternate Bold.woff2") format("woff2");
  font-weight: 600;
}

body {
  margin: 0;
  font-family: "DINAlternate";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}


hr {
  width: 70vw;
}

/* Font */
h1,
h2,
h3 {
  color: #181818;
}
a {
  color: #181818;
  font-weight: 600;
}

h1 {
  font-size: clamp(2.2rem, 4vw, 5rem);
  letter-spacing: 5px;
  margin: 5px 0px;
  font-weight: 100;
}
h2 {
  font-size: 1.9rem;
  letter-spacing: 1.5px;
  font-weight: bold;
}

h3 {
  font-size: 1.7rem;
  letter-spacing: 1.2px;
  font-weight: bold;
}
h4 {
  font-size: 1rem;
  letter-spacing: 2px;
  font-weight: 100;
  text-transform: uppercase;
}
h5 {
  font-size: 1.2rem;
  margin: 15px 0;
  letter-spacing: 1px;
  font-weight: 100;
}

p,
a {
  font-size: 0.95rem;
  letter-spacing: 0.5px;
}

a {
  text-decoration: none;
  font-weight: 400;
}
li {
  list-style: none;
}

@media only screen and (max-width: 768px) {
  h1 {
    letter-spacing: 3px;
  }
  h2 {
    font-size: 1.6rem;
    letter-spacing: 0.8px;
  }

  h3 {
    font-size: 1.3rem;
    letter-spacing: 2px;
  }
  p,
  a {
    font-size: 0.9rem;
    letter-spacing: 0px;
  }
}

@media only screen and (max-width: 420px) {

  h2 {
    font-size: 1.5rem;
    letter-spacing: 0.8px;
  }

  h3 {
    font-size: 1.2rem;
    letter-spacing: 2px;
  }


}
/* Helferklassen */
.uppercase {
  text-transform: uppercase;
}

.font__white {
  color: #fff;
}
.font__blue {
  color: #0071C0
}
.font__red {
  color: #E90014;
  letter-spacing: 2px;
}
.margin__none {
  margin: 0px;
}

.hyphens{
  -webkit-hyphens: manual;
  -moz-hyphens: manual;
  -ms-hyphens: manual;
  hyphens: manual;

}
/* Coming Soon */
.hero--background {
  background-image: url("./images/Startseite.jpg");
  background-size: cover;
  background-position-y: bottom;
  height: 75vh;
  width: 90vw;
  margin: 0px auto;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  .hero--background {
    height: 60vh;
    min-height: 400px;
    padding-top: 100px;
  }
}
.unternehmen--background {
  background-image: url("./images/Hengel Generationen.jpg");
  background-size: cover;
  background-position-y: bottom;
  background-position-x: center;
  height: 75vh;
  width: 90vw;
  margin: 0px auto;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  .unternehmen--background {
    height: 40vh;
    min-height: 240px;
    padding-top: 100px;
  }
}
.team--background {
  background-image: url("./images/Team_MitarbeiterBlau.webp");
  background-size: cover;
  background-position-y: top;
  background-position-x: center;
  height: 75vh;
  width: 90vw;
  margin: 0px auto;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  .team--background {
    height: 40vh;
    min-height: 240px;
    padding-top: 100px;
  }
}
.kontakt--background {
  background-image: url("./images/Haus Firma Hengel.jpg");
  background-size: cover;
  background-position-y: bottom;
  background-position-x: center;
}

@media only screen and (max-width: 1024px) {
  .kontakt--background {
    min-height: 400px;
  }
}
@media only screen and (max-width: 767px) {
  .kontakt--background {
    min-height: 200px;
  }
}
.background__elements {
  background-image: url("./images/Elements Kornwestheim.jpg");
  background-size: cover;
  background-position-y: bottom;
  background-position-x: center;
  min-height: 400px;
  display: flex; 
  align-items: center;
  padding: 30px;
  margin: 10px;
  text-align: center;
}
.background__elements_gc {
  background-image: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url("./images/Flaschnerei.jpg");
  background-size: cover;
  background-position: center; 
  min-height: 400px;
  display: flex; 
  align-items: center;
  padding: 30px 80px;
  margin: 10px;
  text-align: center;
}


@media only screen and (max-width: 1024px) {
  .background__elements {
    min-height: 400px;
  }
}
@media only screen and (max-width: 767px) {
  .background__elements {
    min-height: 200px;
  }
}
.background__lotter {
  background-image: url("./images/Lotter Bädeergalerie.jpg");
  background-size: cover;
  background-position-y: bottom;
  background-position-x: center;
  min-height: 400px;
  display: flex; 
  align-items: center;
  padding: 30px;
  margin: 10px;
  text-align: center;

}

@media only screen and (max-width: 1024px) {
  .background__lotter {
    min-height: 400px;
  }
}
@media only screen and (max-width: 767px) {
  .background__lotter {
    min-height: 200px;
  }
}

.quarterwrapper div hr {
  animation: fadeSpan 1s ease-in;
}

@keyframes fadeSpan {
  0% {
    width: 0;
  }
  100% {
    width: 4vw;
  }
}

/* Timeline */
.MuiTimelineContent-root {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

